$primary-text-color: #394452;
$secondary-text-color: #6D5FFD;
$third-text-color: #FFFFFF;

$primary-button-color: #6D5FFD;
$primary-button-hover-color: rgba(109,95,253, 0.5);

$income-button-background-color: rgb(12, 202, 12);
$expens-button-background-color: rgb(253, 60, 60);

$income-card-background-color: rgba(12, 202, 12, 0.5);
$expens-card-background-color: rgba(253, 60, 60, 0.5);

$add-data-line-color: #FFFFFF;

$form-background-color: rgba(109,95,253, 0.5);

$all-cards-background: rgb(195, 190, 255);

$primary-button-text-color: #6D5FFD;
$secondary-button-text-color: #FFFFFF;

$navbar-background-color: rgba(109,95,253, 0.5);

$error-color: #FF1843;
