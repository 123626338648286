@import './utils/utils.scss';

.layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;

  @include tablets {
    flex-direction: column;
    justify-content: baseline;
  }

  &__navbar {
    display: flex;
    height: 100vh;
    padding: 20px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    background-color: $navbar-background-color;

    @include tablets {
      flex-direction: row;
      height: 70px;
      width: 100%;
    }

    &__item {
      width: 30px;
    }
  }

  &__footer {
    &__author {
      @include subtitle;
      writing-mode: vertical-rl;
      transform: rotate(180deg);

      &:hover {
        color: $secondary-text-color;
        cursor: pointer;
      }
    }

    @include tablets {
      display: none;
    }
  }
}