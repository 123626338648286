@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap');

@mixin title {
  font-family: Source Sans Pro;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: $primary-text-color;
}

@mixin subtitle {
  font-family: Source Sans Pro;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: $primary-text-color;
}

@mixin description {
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 200;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: $primary-text-color;
}

@mixin auth-button-text {
  font-family: Source Sans Pro;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 200;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: $secondary-button-text-color;
}