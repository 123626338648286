@import '../utils/font.scss';

@mixin default-input {
  width: 400px;
  height: 50px;
  padding-left: 15px;
  margin-bottom: 20px;
  border: 1px solid #000000;
  border-radius: 10px;
  @include subtitle;

  @include laptop {
    width: 350px;
  }

  @include phones {
    width: 300px;
  }
}

