@import '../utils/utils.scss';

.login, .register {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;

  @include tablets {
    padding: 20px;
  }

  &__title {
    @include title;
    text-align: center;
    font-style: italic;
    max-width: 800px;
    opacity: 0.9;
  }

  &__text {
    @include description;

    &__link {
      @include description;
      font-size: 1.1rem;
      color: $secondary-text-color;
    }
  }

  &__button {
    @include auth-button;
  }

  &__error {
    @include description;
    color: $error-color;
  }
}