@import './colors.scss';
@import './font.scss';

@mixin auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  @include auth-button-text;
  border: 0;
  border-radius: 10px;
  background-color: $primary-button-text-color;

  @include laptop {
    width: 350px;
  }

  @include phones {
    width: 300px;
  }

  &:hover {
    @include default-hover;
    background-color: $primary-button-hover-color;
  }
}

@mixin history-button {
  width: 30px;
  height: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;

  &:hover {
    @include default-hover;
  }
}

@mixin add-data-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 30px;
  border: 0;
  border-radius: 50%;
  background-color: $primary-button-color;

  &:hover {
    @include default-hover;
    transition: 0.5s;
    transform: scale(2) rotate(360deg);
    background-color: $primary-button-hover-color;

    @include tablets {
      transform: scale(1) rotate(360deg);
      transition: 0.5s;
    }
  }
}

@mixin collection-expenses-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  @include subtitle;
  color: $secondary-button-text-color;
  border: 0;
  border-radius: 10px;

  &:hover {
    @include default-hover;
  }
}

@mixin add-wallet-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  @include subtitle;
  color: $secondary-button-text-color;
  border: 0;
  border-radius: 10px;
  background-color: $primary-button-color;

  &:hover {
    @include default-hover;
  }
}

@mixin delete-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  @include title;
  border: 0;
  border-radius: 10px;

  &:hover {
    @include default-hover;
  }
}