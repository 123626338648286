.home {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 100%;
  height: 100%;
  
  & > * {
    margin: 40px;
  }
}